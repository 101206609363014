.sticky-top {
    top: -1px;
}

.slider .box_search .box form label a {
    flex: 0 0 50%;
}

.slider .box_search .box form label a button {
    text-align: center;
    flex: 0 0 100%;
}

.workspaces h2.white.capitalize {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.workspaces ul {
    width: auto;
}

.banner br {
    display: none;
}

.banner span {
    margin-left: 10px;
}

.wco .boximg img {
    height: 530px;
    object-fit: cover;
}
.sigleblogtext .singlealldata img {
    height: auto;
}

@media (max-width: 1280px) {
    .banner {
        height: 500px;
    }
}

@media (max-width: 1025px) {
    body {
        font-size: 14px;
        line-height: 22px;
    }
    
    h1 {
        font-size: 36px;
        line-height: 46px;
    }
    
    h2 {
        font-size: 32px;
        line-height: 42px;
    }
    
    h3 {
        font-size: 28px;
        line-height: 38px;
    }
    
    h4 {
        font-size: 24px;
        line-height: 34px;
    }
    
    h5 {
        font-size: 20px;
        line-height: 30px;
    }
    
    h6 {
        font-size: 16px;
        line-height: 26px;
    }
    
    a,
    p,
    ul li,
    ol li {
        font-size: 14px;
        line-height: 24px;
    }
    .news ul li {
        width: 50%;
    }
    .modal-body #SIGNUP_DESCRIPTION {
        line-height: 20px;
        font-size: 14px;
    }
    .heos .box {
        display: block;
    }
    .heos .box p {
        max-width: 100%;
    }
    .bepower .box p {
        min-height: 170px;
        margin: 0;
        max-width: 100%;
        padding: 0 16px;
    }
    .bepower:after {
        height: 497px;
    }
    .yyyy ul li {
        flex: 0 0 50%;
        padding: 15px;
        text-align: center;
    }
    .yyyy ul li:nth-child(2) {
        border-right: none;
    }
    .yyyy ul li:nth-child(1), .yyyy ul li:nth-child(2) {
        border-bottom: 1px solid #000;
    }
    iframe.conmap {
        height: auto;
    }
    .comingsoon a .image {
        flex: 0 0 40%;
    }
    .comingsoon a .text {
        flex: 0 0 60%;
    }
}

@media (max-width: 992px) {
    .blogbox .col-md-4 {
        width: 100% !important;
    }
    .blogbox .col-md-8 {
        width: 100% !important;
    }
}

@media (max-width: 991px) {
    .banner {
        height: 400px;
    }
    .benefitsvirtualoffice h2.text-center {
        margin-bottom: 0;
    }
    .benefitsvirtualoffice .col-md-3 {
        width: 50%;
    }
    .benefitsvirtualoffice .box {
        margin-top: 30px;
        height: auto;
    }
}

@media (max-width: 768px) {
    .heos img {
        height: 640px;
    }
    .baes ul li {
        flex: 0 0 23%;
    }
    .ose h5, .ose p {
        padding-left: 0;
    }
    .yyyy.aboutuspage .box h1 {
        font-size: 28px;
    }
    .comingsoon a .image img {
        height: auto;
    }
}

@media (max-width: 767px) {
    header ul li.drop .dropbox {
        z-index: 9;
        width: 100%;
    }

    .slider p {
        font-size: 18px;
        max-width: 275px;
        line-height: 28px;
        margin: 10px auto 20px;
    }

    .upcoming ul.imgal li:nth-child(1) {
        margin-top: 0; 
    }

    .banner {
        height: 250px;
    }

    .osyc p,
    .redefinesuccess {
        text-align: center;
    }

    .news ul li ul li {
        flex: 0 0 50%;
    }

    .news ul li ul.osclastcol {
        padding-left: 15px !important;
    }
    .benefitsvirtualoffice .col-md-3 {
        width: 100%;
    }
    .heos .row {
        text-align: center;
        flex-direction: column-reverse;
    }
    
    .heos img {
        height: auto;
        margin-bottom: 20px;
    }
    .baes ul li {
        flex: 0 0 47%;
    }
    .ose {
        text-align: center;
    }
    .eventspaces .zcsffieldsdiv .zcsffield {
        flex: 0 0 100%;
    }
    .heos {
        padding-top: 30px;
    }
    .redefinesuccess p.mb-0.fw5 {
        margin-top: 15px;
    }
    .yyyy ul {
        margin: 0 0 !important;
    }
    .bepower .box p {
        min-height: auto;
        margin-bottom: 20px !important;
    }
    .bepower:after {
        height: 100%;
    }
    .leadingcompanies {
        margin-top: 50px;
    }
    .aboutvideo iframe {
        height: 300px;
    }
    .blogbox .blogdata .blogtext,
    .blogbox .blogdata .blogimg {
        flex: 0 0 100%;
    }
    .blogbox .blogdata .blogtext {
        padding-left: 0;
        padding-top: 20px !important;
    }
    .blogbox .blogdata .blogtext .bloglink:after {
        display: none;
    }
    .blogbox .blogdata {
        max-width: 100%;
    }
    section.sigleblogtext.blogbox {
        padding-top: 0;
    }
    
    .sigleblogtext.blogbox .col-md-8 {
        text-align: center;
    }
    .conformmain {
        text-align: center;
        padding-top: 0;
    }
    .rigth-box {
        width: 100%;
    }
    .rigth-box ul {
        width: 100%;
        display: flex;
    }
    .rigth-box li {
        flex: 0 0 33.33333%;
        text-align: center;
        padding: 0 !important;
    }
    .Aerocity .col-md-5 img {
        object-position: center;
    }
    .upcoming ul.text li {
        flex: 0 0 100%;
    }
    .amenities h2,
    .Frequently h2,
    .Frequently h5,
    .amenities > p {
        text-align: left !important;
    }
    .comingsoon a {
        flex-wrap: wrap;
    }
    .comingsoon a .image,
    .comingsoon a .text {
        flex: 0 0 100%;
    }
    .comingsoon a .text {
        padding: 15px 0 0;
    }
    header ul li.drop .dropbox {
        position: relative !important;
    }
}


@media (max-width: 575px) {
    .slider .box_search .box form label a button {
        width: 100%;
    }

    h1 {
        font-size: 30px;
        line-height: 40px;
    }
    
    h2 {
        font-size: 26px;
        line-height: 36px;
    }
    
    h3 {
        font-size: 24px;
        line-height: 34px;
    }
    
    h4 {
        font-size: 20px;
        line-height: 30px;
    }
    
    h5 {
        font-size: 16px;
        line-height: 26px;
    }

    .workspaces ul li .box figure span img {
        height: auto;
    }

    .news .box .text h5 {
        text-align: left !important;
    }

    .osuc,
    .passion {
        text-align: center;
    }

    .esy {
        text-align: center;
        padding: 0 15px 40px;
    }

    .esy p {
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
    }

    .cew .row {
        flex-direction: column-reverse;
        text-align: center;
    }

    .cew .row img {
        margin-bottom: 30px;
    }

    .wco .boximg img {
        height: auto;
        margin-bottom: 25px;
    }

    .mosaag iframe {
        min-height: 300px;
    }

    .leadingcompanies {
        padding-top: 0;
    }
    
    .rev {
        flex-direction: column-reverse;
    }

    .conformmain .zcsffieldsdiv .zcsffield,
    .lets .box form .zcsffieldsdiv .zcsffield,
    .modal-body .zcsffieldsdiv .zcsffield {
        flex: 0 0 100%;
    }

    .osuc ul {
        margin: 15px auto !important;
    }
    .data a{
        display: flex;
    justify-content: center;
    align-items: center;
    }

    .osuc .box .data {
        text-align: left;
    }

    .conformmain .col-md-5 img {
        display: none;
    }

    .conformmain div#captchaParent {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    
    .conformmain div#captchaParent img#relCaptcha {
        max-width: 54px !important;
        height: 50px !important;
    }

    div[name="captchaContainer"] div#captchaDiv {
        margin: 10px auto !important;
    }
    div[name="captchaContainer"] img#relCaptcha {
        margin: 0 auto !important;
        display: inherit;
    }
    .wp-block-heading {
        justify-content: center;
    }
    .blogsidebar a.blogpop {
        padding-left: 84px;
    }
    .formdesign {
        width: 100%;
    }
    .formdesign .zcsffieldsdiv {
        flex: 0 0 250px;
    }
    .mainNewsletter .zcsffield input[type="email"] { 
        min-width: 250px;
    }
    .formdesign .formbutton {
        flex: 0 0 70px;
    }
    .formdesign .formbutton input#zcWebOptin {
        padding: 13px 40px !important;
        width: 100%;
    }
}

@media (max-width: 375px) {
    .formdesign .formbutton input#zcWebOptin {
        padding: 13px 15px !important;
    }
}